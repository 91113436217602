import _ from 'lodash';
import React from 'react';
import useSWR from 'swr';
import Image from 'next/image';
import qs from 'qs';
// import { v4 as uuidv4 } from 'uuid';

import { useRouter } from 'next/router';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import MaxWidthContainer from 'components/MaxWidthContainer';
import UserAuthModal from 'components/CommentDetailModal/UserAuthModal';
import AdditionalPhotoModal from 'components/CommentDetailModal/AdditionalPhotoModal';
import CommentModal from 'components/CommentDetailModal/CommentModal';
import { getFileSize } from 'utils/getFileSize';
import { firebaseAuth } from 'utils/firebaseConfig';

import { usePathname } from 'next/navigation';
import redirectToLogin from 'utils/redirectToLogin';
import { MixpanelConfig } from 'utils/mixpanelConfig';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { useAppLaunch } from 'contexts/AppLaunch';
import { useUserAuth } from 'contexts/UserAuth';

import api, { swrFetcher } from 'utils/api';

const { useEffect, useState, useRef, useCallback } = React;

const CommentPopup = (props) => {
  // const { ...restProps } = props;
  const { externalPopupShown, setExternalPopupShown, presetProduct } = props;

  const router = useRouter();

  const { reviewOptions, secondCategoryMap } = useAppLaunch();
  const { isSignInStatusReady, user, getAuthorizationHeader } = useUserAuth();
  const { suspicious = false } = user || {};

  const [selectedProduct, setSelectedProduct] = useState(null);
  // console.log({ user });
  const isCertTutorialShow = user && user.show_review_cert_tutorial;
  const isComparisonTutorialShow = user && user.show_review_comparison_tutorial;
  const isCommentTutorialShow = user && user.show_review_text_tutorial;

  const commentLengthLimit =
    _.get(reviewOptions, 'comment_length_limit', 0) || 0;
  const commentValidLength =
    _.get(reviewOptions, 'comment_valid_length', 0) || 0;
  const commentValidScore = _.get(reviewOptions, 'comment_valid_score', 0) || 0;
  const reviewMetrics = _.get(reviewOptions, 'review_metrics', []) || [];
  const commonReviewQuestion =
    _.get(reviewOptions, 'review_question', '') || '';

  const [reviewMetricsResult, setReviewMetricsResult] = useState([]);

  const [usePresetProduct, setUsePresetProduct] = useState(true);
  const targetProduct =
    usePresetProduct && presetProduct ? presetProduct : selectedProduct;

  const reviewQuestion =
    targetProduct && _.head(targetProduct.second_categories)
      ? _.get(
          secondCategoryMap,
          `${_.head(targetProduct.second_categories).id}.review_question`,
          ''
        ) || commonReviewQuestion
      : commonReviewQuestion;

  useEffect(() => {
    if (reviewMetrics.length !== reviewMetricsResult.length) {
      const initResult = reviewMetrics.map((item) => {
        return { id: item.id, score: 2 };
      });
      setReviewMetricsResult(initResult);
    }
  }, [reviewMetrics]);

  const [isSearchInputFocus, setIsSearchInputFocus] = useState(false);
  const [keyword, setKeyword] = useState('');
  const searchQuery = qs.stringify({
    keyword: keyword,
    init_product_count: 4,
    limit: 8,
  });
  const { data: searchResult, isLoading: isSearchLoading } = useSWR(
    `/api/v2/search?${searchQuery}`,
    swrFetcher,
    {
      fallbackData: {
        products: [],
      },
    }
  );
  const searchProducts = searchResult.products || [];

  const [popupShown, setPopupShown] = useState(false);

  const [commentOverviewState, setCommentOverviewState] = useState(2);

  const [detailCommentState, setDetailCommentState] = useState('');
  const [detailCommentCountState, setDetailCommentCountState] = useState(0);

  const dottedArray = [1, 2, 3, 4, 5];

  const arrCommentOverviewContent = [
    { mark: 1, title: '❤️好評' },
    { mark: 0, title: '👌中性' },
    { mark: -1, title: '👿差評' },
  ];

  const [isUserAuthModalShow, setIsUserAuthModalShow] = useState(false);
  const [isAdditionalPhotoModalShow, setIsAdditionalPhotoModalShow] =
    useState(false);
  const [isCommentModalShow, setIsCommentModalShow] = useState(false);

  const [isUserAuthSessionShow, setIsUserAuthSessionShow] = useState(false);
  const [isUserAdditionalSessionShow, setIsUserAdditionalSessionShow] =
    useState(false);
  const [isCommentSessionShow, setIsCommentSessionShow] = useState(false);

  const [arrAuthImage, setArrAuthImage] = useState([]);
  // const [blockAuthImage, setBlockAuthImage] = useState('');
  const authImageRef = useRef(null);

  const [arrAdditionalImage, setArrAdditionalImage] = useState([]);
  // const [blockAdditionalImage, setBlockAdditionalImage] = useState('');
  const additionalImageRef = useRef(null);

  const scrollRef = useRef(null);

  // const [commentHintShow, setCommentHintShow] = useState(true);
  const [isProductNotFilled, setIsProductNotFilled] = useState(false);
  const [isCommentNotFilled, setIsCommentNotFilled] = useState(false);
  const [isRankNotFilled, setIsRankNotFilled] = useState(false);
  const [isCommentTooLong, setIsCommentTooLong] = useState(false);

  // const [isAddBrandNotFilled, setIsAddBrandNotFilled] = useState(false);
  // const [isAddNameNotFilled, setIsAddNameNotFilled] = useState(false);

  const [isUploadProcessing, setIsUploadProcessing] = useState(false);

  const [isPostError, setIsPostError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const pathname = usePathname();

  const clickAuthRef = () => {
    if (isCertTutorialShow && !isUserAuthSessionShow) {
      setIsUserAuthModalShow(true);
      setIsUserAuthSessionShow(true);
    } else {
      authImageRef.current.click();
    }
  };

  const removeAuthContent = (index) => {
    arrAuthImage.splice(index, 1);
    setArrAuthImage([...arrAuthImage]);
  };

  const addAuthFileContent = (files, isContinueUpload = false) => {
    const checkSize = !isContinueUpload
      ? files.length
      : arrAuthImage.length + files.length;

    if (checkSize <= 1) {
      const tmpArray = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { size } = file;
        if (size > 4000000) {
          alert('每張圖片大小上限為4MB！');
          return;
        } else {
          tmpArray.push(file);
        }
      }
      const placeArray = [...arrAuthImage, ...tmpArray];
      setArrAuthImage(placeArray);
    } else {
      alert('上傳上限為1張圖片');
    }
  };

  const clickAdditionalRef = () => {
    // const localStorageIsAdditionalModalShown = localStorage.getItem('isAdditionalModalNotShow')

    if (isComparisonTutorialShow && !isUserAdditionalSessionShow) {
      setIsAdditionalPhotoModalShow(true);
      setIsUserAdditionalSessionShow(true);
    } else {
      additionalImageRef.current.click();
    }
  };

  const removeAdditionalContent = (index) => {
    arrAdditionalImage.splice(index, 1);
    setArrAdditionalImage([...arrAdditionalImage]);
  };

  const addAdditionalFileContent = (files, isContinueUpload = false) => {
    const checkSize = !isContinueUpload
      ? files.length
      : arrAdditionalImage.length + files.length;

    // console.log({ checkSize })

    if (checkSize <= 3) {
      const tmpArray = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { size } = file;
        if (size > 4000000) {
          alert('每張圖片大小上限為4MB！');
          return;
        } else {
          tmpArray.push(file);
        }
      }
      const placeArray = [...arrAdditionalImage, ...tmpArray];
      setArrAdditionalImage(placeArray);
    } else {
      alert('上傳上限為3張圖片');
    }
  };

  const resetWarningState = () => {
    setIsCommentNotFilled(false);
    setIsProductNotFilled(false);
    setIsRankNotFilled(false);
    setIsCommentTooLong(false);
  };

  const postComment = async () => {
    setErrorMsg('');

    let targetProductId = '';
    if (!targetProduct) {
      setIsProductNotFilled(true);
    } else {
      targetProductId = targetProduct.id;
    }
    setIsRankNotFilled(commentOverviewState == 2);
    setIsCommentNotFilled(detailCommentState == '');
    setIsCommentTooLong(detailCommentState.length > commentLengthLimit);

    const score =
      (detailCommentState.length > commentValidLength ? commentValidScore : 0) +
      (arrAdditionalImage.length > 0 ? 5 : 0) +
      (arrAuthImage.length > 0 ? 10 : 0);
    MixpanelConfig.track('product_commented', {
      product: `${targetProduct.brand ? targetProduct.brand.name : ''} ${targetProduct.name || ''}`, //searchProductKeywordState,
      score: score,
      polarity:
        commentOverviewState == 0
          ? 'neutral'
          : commentOverviewState == 1
            ? 'postive'
            : commentOverviewState == -1
              ? 'negative'
              : '',
    });

    if (targetProductId == '') {
      if (scrollRef.current) {
        scrollRef.current.style.scrollBehavior = 'smooth';
        scrollRef.current.scrollTop = 0;
      }
      return;
    } else if (targetProductId != '' && commentOverviewState == 2) {
      const productBox = document.getElementById('rank_ranking');
      const topPos = productBox.offsetTop;
      if (scrollRef.current) {
        scrollRef.current.style.scrollBehavior = 'smooth';
        scrollRef.current.scrollTop = topPos;
      }
      return;
    } else if (
      targetProductId != '' &&
      commentOverviewState != 2 &&
      detailCommentState == ''
    ) {
      const productBox = document.getElementById('rank_comment');
      const topPos = productBox.offsetTop;
      if (scrollRef.current) {
        scrollRef.current.style.scrollBehavior = 'smooth';
        scrollRef.current.scrollTop = topPos;
      }
      return;
    } else if (
      targetProductId != '' &&
      commentOverviewState != 2 &&
      detailCommentState != '' &&
      detailCommentState.length > commentLengthLimit
    ) {
      const productBox = document.getElementById('rank_comment');
      const topPos = productBox.offsetTop;
      if (scrollRef.current) {
        scrollRef.current.style.scrollBehavior = 'smooth';
        scrollRef.current.scrollTop = topPos;
      }
      return;
    }

    if (!isRankNotFilled && !isProductNotFilled && !isCommentNotFilled) {
      setIsUploadProcessing(true);
      const auth = firebaseAuth;
      const user = auth.currentUser;
      const formData = new FormData();

      const commentData = {
        product_id: targetProductId,
        comment: detailCommentState,
        score: commentOverviewState,
        review_metrics: reviewMetricsResult,
      };
      formData.append('data', JSON.stringify(commentData));

      arrAuthImage.forEach(function (file, index) {
        formData.append('files.cert_images', file, file.name);
      });

      arrAdditionalImage.forEach(function (file, index) {
        formData.append('files.comparison_images', file, file.name);
      });

      const headers = await getAuthorizationHeader();
      api
        .request({
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
          url: '/api/v2/me/review',
          data: formData,
        })
        .then((_) => {
          setIsUploadProcessing(false);
          // console.log('postComment successfully');
          if (suspicious) {
            window.location = '/profile/score';
          } else {
            window.location = `/product/reviews/${targetProductId}`;
          }
        })
        .catch((err) => {
          setIsUploadProcessing(false);
          setIsPostError(true);
          setErrorMsg(err.response.data.message);
        });
    }
  };

  const [instantKeyword, setInstantKeyword] = useState('');
  const debouncedSearchKeyword = useCallback(
    _.debounce((value) => {
      setKeyword(value);
    }, 300),
    [setKeyword]
  );

  useEffect(() => {
    debouncedSearchKeyword(instantKeyword);
  }, [instantKeyword]);

  return (
    <>
      <div
        className={
          'fixed bottom-0 left-0 z-[60] h-full w-full lg:bottom-[144px] lg:h-[calc(100vh-144px)]' +
          (popupShown == true || externalPopupShown == true
            ? ' block'
            : ' hidden')
        }
        style={{ pointerEvents: popupShown == false ? 'none' : 'auto' }}
        id="comment_box"
        onClick={() => {
          if (popupShown == true) {
            setPopupShown(false);
            setExternalPopupShown(false);
          }
        }}
      >
        <MaxWidthContainer className="flex h-full w-full justify-end">
          <div
            className="commentPopup-container h-full w-full overflow-hidden rounded-none bg-white lg:mb-[0px] lg:mt-[auto] lg:max-h-[670px] lg:w-[480px] lg:rounded-lg"
            style={{ pointerEvents: 'auto' }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="relative top-0 z-30 w-full bg-white">
              <div className="left-0 top-0 h-[96px] w-full px-[16px] py-[24px]">
                <button
                  className="border-text-gray-100 absolute left-[16px] mb-2 mr-2 h-[48px] w-[48px] rounded-lg border p-[14px] text-center text-sm font-medium text-gray-100 focus:outline-none focus:ring-4 focus:ring-blue-300"
                  onClick={() => {
                    setPopupShown(false);
                    setExternalPopupShown(false);
                    // setPopupState(0);
                  }}
                >
                  <img
                    src="/svgs/modal-close.svg"
                    className="h-[20px] w-[20px]"
                  ></img>
                </button>

                <div className="flex h-[48px] items-center justify-center text-center text-lg font-medium text-sorra-gray-800 lg:hidden">
                  留評價
                </div>
              </div>
            </div>

            <div
              ref={scrollRef}
              className="relative max-h-[calc(100%-96px)] overflow-scroll px-4"
            >
              <div className="flex items-center space-x-2" id="rank_item">
                <div className="text-base font-medium text-sorra-gray-900">
                  選擇評價的產品
                </div>
                {isProductNotFilled ? (
                  <div className="flex items-center space-x-1 text-sm text-sorra-orange-warning">
                    <Image
                      src="/svgs/warning.svg"
                      alt="warning"
                      className="mt-1"
                      width={16}
                      height={16}
                    />
                    <span>必填欄位</span>
                  </div>
                ) : null}
                <div
                  className="commentPopup-addProduct flex cursor-pointer text-sm font-medium text-sorra-pink"
                  onClick={() => {
                    window.location = '/nominate';
                  }}
                >
                  <img src="/svgs/folder-add.svg" className="mr-[4px]"></img>
                  新增產品
                </div>
              </div>

              <div className="relative mb-[24px] mt-[8px] h-[48px] w-full">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                  <img src="/svgs/search.svg"></img>
                </div>
                <input
                  type="text"
                  id=""
                  className={`bg-gray-0 border ${
                    isProductNotFilled
                      ? 'border-sorra-orange-warning'
                      : 'border-gray-300'
                  } block h-full w-full rounded-lg p-2.5 pl-[56px] font-medium text-sorra-gray-900 focus:border-gray-800 focus:ring-0 focus:ring-offset-0`}
                  placeholder="美妝產品"
                  autoComplete="false"
                  autoCorrect="false"
                  value={
                    isSearchInputFocus
                      ? instantKeyword
                      : targetProduct
                        ? `${targetProduct.brand ? `${targetProduct.brand.name} ` : ''}${targetProduct.name || ''}`
                        : ''
                  }
                  onFocus={() => {
                    setIsSearchInputFocus(true);
                    setUsePresetProduct(false);
                    // setInstantKeyword('');
                    setSelectedProduct(null);
                  }}
                  onBlur={(event) => {
                    if (_.isEmpty(instantKeyword)) {
                      setIsSearchInputFocus(false);
                    }
                  }}
                  onChange={(event) => {
                    const value = event.target.value;
                    setInstantKeyword(value);
                    resetWarningState();
                    setIsPostError(false);
                  }}
                />
                <button
                  className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                  onClick={() => {
                    setUsePresetProduct(false);
                    setInstantKeyword('');
                    setSelectedProduct(null);
                    resetWarningState();
                    setIsPostError(false);
                  }}
                >
                  {targetProduct || instantKeyword ? (
                    <Image
                      src="/svgs/clear.svg"
                      alt="clear"
                      width={14}
                      height={14}
                    />
                  ) : (
                    ''
                  )}
                </button>
              </div>

              {isSearchInputFocus && (
                <div>
                  {isSearchLoading ? (
                    <div className="flex w-full items-center justify-center">
                      <img
                        className="h-[160px] w-[160px]"
                        src="/images/search-placeholder.gif"
                      ></img>
                    </div>
                  ) : (
                    <div className="flex w-full flex-col gap-y-4">
                      {searchProducts.map((product) => {
                        const { id, name, brand, images } = product;
                        const image = _.head(images);
                        const imageURL = image
                          ? image.url
                          : '/images/placeholders/product.png';
                        return (
                          <div
                            key={id}
                            className="flex h-10 cursor-pointer gap-x-4"
                            onMouseDown={(event) => {
                              setIsSearchInputFocus(false);
                              setSelectedProduct(product);
                              setInstantKeyword('');
                            }}
                            onClick={(event) => {
                              setIsSearchInputFocus(false);
                              setSelectedProduct(product);
                              setInstantKeyword('');
                            }}
                          >
                            <img src={imageURL} className="h-10 w-10"></img>
                            <div className="h-full w-full">
                              <div className="text-sm font-medium text-sorra-gray-900">
                                {name || ''}
                              </div>
                              <div className="pt-0.5 text-sm font-medium text-sorra-gray-800">
                                {brand ? brand.name : ''}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              {isSearchInputFocus && (
                <div className="mt-6 border-t-[1px] border-gray-300"></div>
              )}
              {/* <div
                className={
                  'space-y-[16px]' +
                  (searchProductInputFocusState == true ? ' block' : ' hidden')
                }
              >
                {blockSearchProductState}
              </div> */}

              {/* <div
                className={
                  'mt-[24px] border-t-[1px] border-gray-300' +
                  (searchProductInputFocusState == true ? ' block' : ' hidden')
                }
              ></div> */}

              {/* <div>
                                    Selected Product ID : {searchProductIdState}
                                </div> */}

              <div className="mt-[24px] font-[500]" id="rank_ranking">
                <div className="flex items-center space-x-2">
                  <div className="text-base font-medium text-sorra-gray-900">
                    對此產品的整體評價
                  </div>
                  {isRankNotFilled ? (
                    <div className="flex items-center space-x-1 text-sm text-sorra-orange-warning">
                      <Image
                        src="/svgs/warning.svg"
                        alt="warning"
                        className="mt-1"
                        width={16}
                        height={16}
                      />
                      <span>必填欄位</span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mt-[8px] flex items-center justify-between lg:justify-start lg:space-x-4">
                {arrCommentOverviewContent.map((item, idx) => (
                  <div
                    key={idx}
                    className={`cursor-pointer rounded-[16px] border-[1px] px-[28px] py-[16px] text-[#4A4A4A] ${
                      commentOverviewState === item.mark
                        ? 'border-sorra-pink bg-sorra-pink-light'
                        : 'border-[#9B9B9B]'
                    }`}
                    onClick={() => setCommentOverviewState(item.mark)}
                  >
                    {item.title}
                  </div>
                ))}
              </div>

              <div className="mt-[24px] font-[500]" id="rank_comment">
                <div className="flex items-center space-x-2">
                  <div className="text-base font-medium text-sorra-gray-900">
                    對此產品的詳細評價
                  </div>
                  {isCommentNotFilled ? (
                    <div className="flex items-center space-x-1 text-sm text-sorra-orange-warning">
                      <Image
                        src="/svgs/warning.svg"
                        alt="warning"
                        className="mt-1"
                        width={16}
                        height={16}
                      />
                      <span>必填欄位</span>
                    </div>
                  ) : null}
                  {isCommentTooLong ? (
                    <div className="flex items-center space-x-1 text-sm text-sorra-orange-warning">
                      <Image
                        src="/svgs/warning.svg"
                        alt="warning"
                        className="mt-1"
                        width={16}
                        height={16}
                      />
                      <span>超出評價上限{commentLengthLimit}字</span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="relative mt-2">
                <div
                  className={`border pb-10 ${
                    isCommentNotFilled || isCommentTooLong
                      ? 'border-sorra-orange-warning'
                      : 'border-gray-300'
                  } relative w-full rounded-lg focus:border-gray-800`}
                >
                  {isCommentTutorialShow && !isCommentSessionShow ? (
                    <div
                      className="absolute bottom-0 left-0 right-0 top-0 z-[1]"
                      onClick={() => {
                        setIsCommentModalShow(true);
                        setIsCommentSessionShow(true);
                      }}
                    />
                  ) : null}
                  <textarea
                    className={`relative mt-[8px] h-[140px] w-full overflow-auto border-transparent text-start focus:border-transparent focus:ring-0`}
                    placeholder="留下您的評價，幫助更多想買的人！"
                    onChange={(e) => {
                      resetWarningState();
                      setDetailCommentState(e.target.value);
                      setDetailCommentCountState(e.target.value.length);
                      // setCommentHintShow(false);
                    }}
                  />
                </div>
                <div className="absolute bottom-[16px] left-[16px] right-[16px] flex items-center justify-between">
                  <div className="text-[12px] font-[400] text-sorra-gray-800">{`現時：${detailCommentCountState}字`}</div>
                  <div className="flex items-center rounded-md bg-[#F5F5F5] px-[8px] py-[4px] text-[12px] text-sorra-gray-800">
                    {`超過${commentValidLength}字評價`}+&nbsp;
                    <div className="flex h-[16px] w-[16px] items-center rounded-[50%] bg-sorra-pink-light">
                      <img
                        src="/svgs/sorra-logo-small.svg"
                        className="p-[4px]"
                      />
                    </div>{' '}
                    ${`${commentValidScore}`}
                  </div>
                </div>
              </div>

              {/* {commentHintShow ? ( */}
              <div className="mt-4 text-[14px] text-sorra-gray-800">
                評價提示:
                <br />
                {}
                {/* {hintText} */}
                {/* {commonReviewQuestion} */}
                {/* <br /> */}
                {reviewQuestion}
              </div>
              {/* ) : null} */}

              {reviewMetrics.map((item, index) => {
                return (
                  <div key={item.id} className="mt-[24px]">
                    <div className="flex items-center">
                      <div className="mr-[24px] font-[500]">{item.name}</div>
                      <div className="flex flex-grow flex-col">
                        <div className="text-grey-800 flex items-center justify-between text-[12px]">
                          <div>{item.low}</div>
                          <div>{item.high}</div>
                        </div>
                        <div className="mt-[12px]">
                          <Slider
                            min={0}
                            max={4}
                            // defaultValue={2}
                            value={
                              reviewMetricsResult[index] &&
                              reviewMetricsResult[index].score
                            }
                            onChange={(value) => {
                              const currResult = reviewMetricsResult;
                              currResult[index].score = value;
                              setReviewMetricsResult([...currResult]);
                            }}
                            trackStyle={{
                              backgroundColor: '#F16B7C',
                              height: '8px',
                            }}
                            railStyle={{
                              backgroundColor: '#E5E7EB',
                              height: '8px',
                            }}
                            handleStyle={{
                              borderColor: '#E7E7E7',
                              borderWidth: '1px',
                              backgroundColor: 'white',
                              marginTop: '-4px',
                              width: '16px',
                              height: '16px',
                              opacity: '1',
                            }}
                          />
                          <div className="mt-[8px] flex items-center justify-between">
                            {dottedArray.map((_, idx) => (
                              <div
                                key={idx}
                                className="h-[4px] w-[4px] rounded-[50%] bg-[#D9D9D9]"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center font-[500]">
                    真實用家認證
                    <img
                      src="/svgs/information-circle.svg"
                      width={20}
                      height={20}
                      alt="info"
                      className="ml-[8px] mt-[2px] cursor-pointer"
                      onClick={() => setIsUserAuthModalShow(true)}
                    />
                  </div>
                  <div className="flex items-center rounded-md bg-[#F5F5F5] px-[8px] py-[4px] text-[12px] text-sorra-gray-800">
                    上傳後額外+&nbsp;
                    <div className="flex h-[16px] w-[16px] items-center rounded-[50%] bg-sorra-pink-light">
                      <img
                        src="/svgs/sorra-logo-small.svg"
                        className="p-[4px]"
                      />
                    </div>{' '}
                    $10
                  </div>
                </div>
                {arrAuthImage.length === 0 ? (
                  <div
                    className="mt-[12px] flex cursor-pointer items-center rounded-lg border p-[24px] text-gray-800"
                    onClick={clickAuthRef}
                  >
                    <img
                      src="/svgs/upload.svg"
                      width={20}
                      height={20}
                      alt="upload"
                      className="mr-[8px] mt-[2px]"
                    />
                    背面或底部的照片，或購買憑證
                    <input
                      type="file"
                      multiple="multiple"
                      accept="image/*"
                      id="additionalImage"
                      hidden
                      ref={authImageRef}
                      onChange={(event) =>
                        addAuthFileContent(event.target.files)
                      }
                    />
                  </div>
                ) : (
                  <div className="mt-3 cursor-pointer rounded-lg border border-[#D1D5DB]">
                    {arrAuthImage.map((item, index) => {
                      const { name } = item;
                      return (
                        <div
                          key={name}
                          className="border-b-[1px] border-[#D1D5DB] px-[16px] py-[24px]"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                              <div>
                                <img
                                  className="h-10 w-10 object-cover"
                                  src={URL.createObjectURL(item)}
                                  // alt="auth"

                                  // width={40}
                                  // height={40}
                                />
                              </div>
                              <div className="flex flex-col space-y-1">
                                <div className="text-[14px] font-medium text-[#111928]">
                                  {item.name}
                                </div>
                                <div className="text-[14px] font-medium text-sorra-gray-800">
                                  {getFileSize(item.size)}
                                </div>
                              </div>
                            </div>
                            <div
                              className="w-5 cursor-pointer"
                              onClick={() => removeAuthContent(index)}
                            >
                              <img
                                src="/svgs/trash.svg"
                                width={20}
                                height={20}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {arrAuthImage.length < 10 && (
                      <div
                        className="flex items-center px-[24px] py-[24px]"
                        onClick={clickAuthRef}
                      >
                        <img
                          src="/svgs/upload.svg"
                          width={20}
                          height={20}
                          alt="upload"
                          className="mr-[8px] mt-[2px]"
                        />
                        上傳認證照
                        <input
                          type="file"
                          multiple="multiple"
                          accept="image/*"
                          id="additionalImage"
                          hidden
                          ref={authImageRef}
                          onChange={(event) =>
                            addAuthFileContent(event.target.files, true)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                {/* {blockAuthImage} */}
              </div>

              <div className="mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center font-[500]">
                    附加照片
                    <img
                      src="/svgs/information-circle.svg"
                      width={20}
                      height={20}
                      alt="info"
                      className="ml-[8px] mt-[2px]"
                      onClick={() => setIsAdditionalPhotoModalShow(true)}
                    />
                  </div>
                  <div className="flex items-center rounded-md bg-[#F5F5F5] px-[8px] py-[4px] text-[12px] text-sorra-gray-800">
                    上傳後額外+&nbsp;
                    <div className="flex h-[16px] w-[16px] items-center rounded-[50%] bg-sorra-pink-light">
                      <img
                        src="/svgs/sorra-logo-small.svg"
                        className="p-[4px]"
                      />
                    </div>{' '}
                    $5
                  </div>
                </div>
                {arrAdditionalImage.length === 0 ? (
                  <div
                    className="mt-[12px] flex cursor-pointer items-center rounded-lg border p-[24px] text-gray-800"
                    onClick={clickAdditionalRef}
                  >
                    <img
                      src="/svgs/upload.svg"
                      width={20}
                      height={20}
                      alt="upload"
                      className="mr-[8px] mt-[2px]"
                    />
                    前後對比圖／妝容效果／試色／質地
                    <input
                      type="file"
                      multiple="multiple"
                      accept="image/*"
                      id="additionalImage"
                      hidden
                      ref={additionalImageRef}
                      onChange={(event) =>
                        addAdditionalFileContent(event.target.files)
                      }
                    />
                  </div>
                ) : (
                  <div className="mt-[12px] cursor-pointer rounded-lg border-[1px] border-[#D1D5DB]">
                    {arrAdditionalImage.map((item, index) => {
                      const { name } = item;
                      return (
                        <div
                          key={name}
                          className="border-b-[1px] border-[#D1D5DB] px-[16px] py-[24px]"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                              <div>
                                <img
                                  className="h-10 w-10 object-cover"
                                  src={URL.createObjectURL(item)}
                                />
                              </div>
                              <div className="flex flex-col space-y-1">
                                <div className="text-[14px] font-medium text-[#111928]">
                                  {item.name}
                                </div>
                                <div className="text-[14px] font-medium text-sorra-gray-800">
                                  {getFileSize(item.size)}
                                </div>
                              </div>
                            </div>
                            <div
                              className="cursor-pointer"
                              onClick={() => removeAdditionalContent(index)}
                            >
                              <img
                                src="/svgs/trash.svg"
                                width={20}
                                height={20}
                                alt="trash"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {arrAdditionalImage.length < 10 && (
                      <div
                        className="flex items-center px-[24px] py-[24px]"
                        onClick={clickAdditionalRef}
                      >
                        <img
                          src="/svgs/upload.svg"
                          width={20}
                          height={20}
                          alt="upload"
                          className="mr-[8px] mt-[2px]"
                        />
                        上傳附加照片
                        <input
                          type="file"
                          multiple="multiple"
                          accept="image/*"
                          id="additionalImage"
                          hidden
                          ref={additionalImageRef}
                          onChange={(event) =>
                            addAdditionalFileContent(event.target.files, true)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                {/* {blockAdditionalImage} */}
              </div>

              {!isEmpty(errorMsg) ? (
                <div className="flex items-center gap-x-1 pt-[18px] text-[14px] font-normal text-[#9B1C1C]">
                  <img
                    src="/svgs/error.svg"
                    width={16}
                    height={16}
                    alt="error"
                    className="mt-1"
                  />
                  {errorMsg}
                  {/* 抱歉，您已評價過該產品！請1周後再追加評價。 */}
                </div>
              ) : null}

              <div
                className={classnames('pb-6', {
                  'pt-[22px]': isPostError,
                  'pt-10': !isPostError,
                })}
              >
                <button
                  className={`${
                    !isUploadProcessing
                      ? 'bg-sorra-pink'
                      : 'bg-sorra-pink-light'
                  } flex h-[48px] w-full flex-row items-center justify-center rounded-[8px] text-[#fff]`}
                  onClick={!isUploadProcessing ? postComment : null}
                  disabled={isUploadProcessing}
                >
                  {!isUploadProcessing ? (
                    <>
                      <img
                        alt="chat"
                        src="/svgs/chat.svg"
                        height={20}
                        width={20}
                        className="mr-[12px]"
                      />
                      <p>留評價</p>
                    </>
                  ) : (
                    <p>上傳評價中...</p>
                  )}
                </button>
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      </div>

      <div
        className="fixed bottom-0 left-0 z-30 w-full pt-[24px]"
        style={{ pointerEvents: popupShown == false ? 'none' : 'auto' }}
        onClick={() => {
          if (popupShown == true) {
            setPopupShown(false);
            setExternalPopupShown(false);
          }
        }}
      >
        <MaxWidthContainer
          className={classnames(
            'mb-[86px] flex justify-end px-4 lg:mb-[48px]',
            {
              hidden: !isSignInStatusReady,
              flex: isSignInStatusReady,
            }
          )}
        >
          <div
            className={
              'z-40 h-[48px] w-[48px] cursor-pointer rounded-full bg-sorra-pink p-[12px] lg:h-[72px] lg:w-[72px] lg:p-[18px]' +
              (user ? '' : ' hidden')
            }
            style={{ pointerEvents: 'auto' }}
            onClick={() => {
              if (popupShown == true) {
                setPopupShown(false);
              } else {
                if (user) {
                  setPopupShown(true);
                } else {
                  redirectToLogin(pathname);
                }
              }
            }}
          >
            <img className="h-full w-full" src="/svgs/pencil.svg"></img>
          </div>
          <div
            className={
              'h-[58px] w-[52px] cursor-pointer lg:h-[88px] lg:w-[78px]' +
              (!user ? '' : ' hidden')
            }
            style={{ pointerEvents: 'auto' }}
            onClick={() => {
              MixpanelConfig.track('signinup_from_flowingicon');
              redirectToLogin(pathname);
            }}
          >
            <img className="h-full w-full" src="/svgs/comment-login.svg"></img>
          </div>
        </MaxWidthContainer>
      </div>

      <UserAuthModal
        isShow={isUserAuthModalShow}
        setIsShow={setIsUserAuthModalShow}
        authImageRef={authImageRef}
      />
      <AdditionalPhotoModal
        isShow={isAdditionalPhotoModalShow}
        setIsShow={setIsAdditionalPhotoModalShow}
        additionalImageRef={additionalImageRef}
      />
      <CommentModal
        isShow={isCommentModalShow}
        setIsShow={setIsCommentModalShow}
      />
    </>
  );
};

export default CommentPopup;
